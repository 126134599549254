function Links() {
  const Link = ({ href, title, children }) => (
    <a target="_blank" rel="noreferrer" href={href} title={title}>
      {children}
      <i className="bi bi-chevron-compact-right"></i>
    </a>
  )
  return (
    <>
      <h3>Links</h3>
      <ul>
        <li>
          <Link
            href="https://se.linkedin.com/in/petertornhult"
            title="Linkedin"
          >
            Linked
            <i className="bi bi-linkedin"></i>
          </Link>
        </li>
        <li>
          <Link href="https://medium.com/@peter.tornhult">Blog</Link>
        </li>
        <li>
          <Link href="https://www.meetup.com/STHLM-Android/">Meetup</Link>
        </li>
        <li>
          <Link href="https://tornhult.se">Tornhult</Link>
        </li>
      </ul>
    </>
  )
}

export default Links
