function Info() {
  return (
    <>
      <p>
        Code Unlimited is a playground for building hobby projects and trying
        out new technologies. I believe learning by doing is one of the best
        ways to learn and this site helps me do just that. I've been working as
        a consultant since the early 2000 and started coding long before that.
        Working with both hardware and software has also been a great way to
        learn and understand how things work together
      </p>
      <p>
        I've worked with companies big and small such as: Klarna, Bookbeat, ASSA
        Abloy, UR (Utbildnings Radion), Kindred / Unibet, ATG and many more...
      </p>
      <p>
        Apart from coding I also write blogs, do presentations and host meetups
      </p>
      <p>
        As of writing this I'm focusing on Android development, but also keeping
        up with React (mostly for fun). Technologies change over time so that
        may quickly change ;)
      </p>
      <p className="signed">/Peter -23</p>
    </>
  )
}

export default Info
