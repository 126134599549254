import Info from '../info'
import Links from '../links'

function Home() {
  return (
    <div className="container">
      <h1>{'// Code Unlimited'}</h1>
      <h2>
        Building things for fun using the latest and greatest technologies...
      </h2>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <Links />
        </div>
        <div className="col-sm-12 col-md-9">
          <Info />
        </div>
      </div>
    </div>
  )
}

export default Home
